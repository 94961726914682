export const useLogin = async (name: string, pass :string, callback?) => {
  const useError = () => useState('error')
  const error = useError()
  const { $currentUser: currentUser, $refreshUser: refreshUser } = useNuxtApp()

  try {
    const resp = await apiMutate("/user/login?_format=json", {name, pass})
    if (resp?.csrf_token) {
      storageSet('csrf_token', resp.csrf_token)
      storageSet('logout_token', resp.logout_token)
      await refreshUser()
      const user = await currentUser()
      if (user.value) {
        const userData = { ...resp.current_user, ...user.value }
        user.value = userData
        useState('user').value = userData
      }
      if (callback) {
        callback()
      }
    }
    if (resp?.message) {
      error.value = resp.message
    }
  } catch (err) {
    error.value = err
  }
  return { user: useState('user'), error }
}

export const useLogout = () => {
  const token = storageGet("logout_token")
  const user = useState('user')
  storageRemove('csrf_token')
  storageRemove('logout_token')
  user.value = null
  apiFetch("/user/logout?_format=json&token=" + token.value, {})
}

export const useRegistering = (data: any) => {
  return apiMutate("/user/register?_format=json", {
    "name": {"value": data.name},
    "field_nom": {"value": data.nom},
    "field_prenom": {"value": data.prenom},
    "field_telephone":{"value": data.telephone},
    "mail": {"value": data.mail},
    "pass": {"value": data.pass},
    "user_picture": {"target_id": data.picture}
  },
  {
    credentials: 'omit'
  })
}

export const useUpdateUser = (data: any) => {
  return apiMutate("/user", {
    "field_nom": {"value": data.nom},
    "field_prenom": {"value": data.prenom},
    "field_telephone":{"value": data.telephone},
    "user_picture": {"target_id": data.picture}
  }, { "id": data.id})
}
